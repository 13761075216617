<template>
  <div>
    <div class="control">
      <button class="btn btn-primary" @click="setEquipement()"><i class="fa fa-lightbulb-o"></i> Equipements</button>
      &nbsp;-&nbsp;
      <button class="btn btn-primary" @click="setRegulateur()"><i class="fa fa-bolt"></i> Régulateurs/Départs</button>
      &nbsp;-&nbsp;
      <span v-if="modeProjet">
        <button class="btn btn-info" @click="setProjet()" v-if="!isProjet"><i class="fa fa-border"></i> Projet</button>
        <button class="btn btn-success" @click="setExistant()" v-if="isProjet"><i class="fa fa-border"></i>
          Projet</button>
        &nbsp;-&nbsp;
      </span>
      <button class="btn btn-primary" @click="setAction()"><i class="fa fa-folder-open-o"></i> Actions</button>
      &nbsp;-&nbsp;
      <button class="btn btn-primary" @click="setPlan()"><i class="fa fa-map"></i> Plan</button>
      &nbsp;-&nbsp;
      <button class="btn btn-primary" @click="setSatellite()"><i class="fa fa-globe"></i> Satellite</button>
      &nbsp;-&nbsp;
      <button class="btn btn-primary" @click="setNord()"><i class="fa fa-arrow-circle-o-up"></i> Nord</button>
      &nbsp;-&nbsp;
      <button class="btn btn-primary" @click="setPiste()"><i class="fa fa-road"></i> Piste</button>
      <span v-if="chambreOK">
        &nbsp;-&nbsp;
        <button class="btn btn-primary" @click="toggleChambres()"><i class="fa fa-border"></i> Chambres</button>
      </span>
      {{ initialPos() }}
    </div>
    <div class="detail" v-if="detailchambre">
      <div class="row">
        <div class="col-sm-1">
          <a href="#" @click="hidedetail()" data-toggle="tooltip" data-placement="top"
            title="Cacher les informations"><i class="fa fa-window-close"></i> Cacher</a><br />
        </div>
        <div class="col-sm-2">
          {{ chambremodal.massif }} : {{ chambremodal.nom }}<br />
          Type : {{ chambremodal.typechambre }}<br />
        </div>
        <div class="col-sm-2">
          Matériau Tampon : {{ chambremodal.mattampon }}<br />
          Forme Tampon : {{ chambremodal.formetampon }}<br />
          Résistance : {{ chambremodal.resistance }}<br />
        </div>
      </div>
    </div>
    <div class="detail" v-if="detailfeu">
      <div class="row">
        <div class="col-sm-1">
          <a href="#" @click="hidedetail()" data-toggle="tooltip" data-placement="top"
            title="Cacher les informations"><i class="fa fa-window-close"></i> Cacher</a><br />
          <a href="#" @click="viewfeu(idModal)" data-toggle="tooltip" data-placement="top"
            title="Voir les détails de l'équipement"><i class="fa fa-lightbulb-o"></i> Détails</a><br />
          <br />
          <a :href="doc(feuModal.docfeu)" target="_blank"><img src="../assets/img/pdf.png" data-toggle="tooltip"
              data-placement="top" title="Voir la notice de l'équipement" /></a>
        </div>
        <div class="col-sm-2">
          <img :src="feuModal.imgfeu" />
        </div>
        <div class="col-sm-1">
          <a href="#" @click="viewfeu(idModal)"><img :src="feuModal.imgStatut" data-toggle="tooltip"
              data-placement="top" :title="alt(feuModal.statut)" /></a>
        </div>
        <div class="col-sm-2">
          Equipement : <strong>{{ feuModal.nomfeu }}</strong><br />
          {{ feuModal.typefeu }} {{ feuModal.serigraphie }}<br />
          Régulateur : <strong>{{ feuModal.nomreg }}</strong><br />
          Couleur : <img :src="svg(feuModal.strcode)" /> {{ feuModal.couleur
          }}<br />
          <span v-if="mesure != null">
            Dernière mesure photométrique :
            <span v-if="mesure.mesure == 1" :class="couleur(mesure)"> {{
              action(mesure)
            }}</span>
            <span v-if="mesure.mesure2 == 1" :class="couleur2(mesure)"> {{
              action2(mesure)
            }}</span>
          </span>
        </div>
        <div class="col-sm-6">
          <span v-if="serigraphie">
            <img :src="nomfichier" />
          </span>
        </div>
      </div>
    </div>

    <div id="map"></div>
  </div>
</template>

<script>
import store from "../store"
import { imgApi, getPrefix, selectApi } from "../lib/api.js"
import { getPath, scaleColor, target, chamber, massif } from "../lib/svg.js"
import { cvRotation, calcAttenuation } from "../lib/fct.js";
import Vue from "vue"
import "ol/ol.css"
import Map from 'ol/Map'
import { OSM, XYZ, Vector as VectorSource } from 'ol/source'
import { Icon, Style, Stroke } from 'ol/style'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import View from 'ol/View'
import { transform } from 'ol/proj.js'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import LineString from 'ol/geom/LineString'
import GeoJSON from "ol/format/GeoJSON"
import { link } from "fs"

// --------------------- Layers --------------
// 0 Layer symbol Hidden
// 1 Layer symbol Hidden
// 2 Layer symbol Hidden
// 3 Layer symbol Hidden
// 4 Layer plan Hidden
// 5 Layer plan Visible
// 6 Layer symbol Visible
// 7 Layer chambre/liens Visible/Hidden
// --------------------- Layers --------------


let mark = false;

let equip = [];
let prequip = [];
let regul = [];
let prregul = [];
let action = [];
let chb = [];

let output = null;

let markequip = null;
let markprequip = null;
let markregul = null;
let markprregul = null;
let markaction = null;
let markchambre = null;

let vectorEquip = null;
let vectorPrEquip = null;
let vectorRegul = null;
let vectorPrRegul = null;
let vectorAction = null;
let vectorChambre = null;

function watchPosition() {

  if ("geolocation" in navigator) {
    const watchID = navigator.geolocation.watchPosition(function (position) {
      window.myPos = { lat: position.coords.latitude, lng: position.coords.longitude }

      let point = { lat: window.myPos.lat, lng: window.myPos.lng };

      const geo = new Point(transform([point.lng, point.lat], 'EPSG:4326', 'EPSG:3857'))
      changeMark(mark, geo);

    }, function () {
    })
  }
}

function changeMark(mark, geo) {

  if (mark == false) return;

  equip[0].setGeometry(geo);
  prequip[0].setGeometry(geo);
  regul[0].setGeometry(geo);
  prregul[0].setGeometry(geo);
  action[0].setGeometry(geo);
}

function setMarker(point, svg, id = 0, idfonction = 0, ischambre = 0, ishape = 0) {

  let iconStyle = new Style({
    image: new Icon({
      opacity: 1,
      src: svg
    })
  })

  if (ishape != 0) {
    iconStyle = new Style({
      image: new Icon({
        opacity: 1,
        src: getPrefix() + "/img/target.png"

      })
    })
  }




  let feature = new Feature({
    geometry: new Point(transform([point.lng, point.lat], 'EPSG:4326', 'EPSG:3857'))
  });

  feature.id_ = id;
  feature.feuchambre_ = ischambre;
  feature.setStyle(iconStyle);
  return feature;
}

function setLine(point1, point2, color = 'black') {

  let lineStyle = new Style({
    stroke: new Stroke({ color: color })
  })

  let feature = new Feature({
    geometry: new LineString([transform([point1.lng, point1.lat], 'EPSG:4326', 'EPSG:3857'), transform([point2.lng, point2.lat], 'EPSG:4326', 'EPSG:3857')])
  })
  feature.setStyle(lineStyle);
  return feature
}

export default {
  name: "Map",
  store: store,
  data() {
    return {
      idfeu: this.$route.params.id == undefined ? 0 : this.$route.params.id,
      detailfeu: false,
      detailchambre: false,
      map: null,
      mapCenter: { lat: 48.85840260089159, lng: 2.2944824899795937 },
      rotation: 0,
      count: 0,
      countc: 0,
      countr: 0,
      feuModal: {},
      idModal: 0,
      feux: [],
      chambres: [],
      mesure: null,
      countm: 0,
      mesures: [],
      infofeu: "",
      inforeg: "",
      typefeu: true,
      typeplan: true,
      nbactions: 0,
      osm: null,
      xvy: null,
      connect: null,
      serigraphie: false,
      nomfichier: '',
      chambreVisible: false,
      chambreOK: false,
      reseaux: [],
      chambremodal: {},
      modeProjet: false,
      isProjet: false,
      mode: 0
    };
  },
  computed: {},
  filters: {
    sdt: function (dt) {
      if (!dt) return "";
      return (
        dt.substring(8, 10) +
        "/" +
        dt.substring(5, 7) +
        "/" +
        dt.substring(0, 4)
      );
    },
  },
  methods: {
    initialPos() {
      return ''; // return '&nbsp;-&nbsp; [' + window.myPos.lat + ',' + window.myPos.lng + ']'; 
    },
    toggleChambres() {
      this.chambreVisible = !(this.chambreVisible);
      markchambre.setZIndex(this.chambreVisible ? 5 : 0);
    },
    setNord() {
      let view = this.map.getView();
      view.setRotation(0);
    },
    setPiste() {
      let view = this.map.getView();
      view.setRotation(cvRotation(this.rotation));
    },
    setProjet() {
      this.isProjet = true;
      if (this.mode == 1) this.setEquipement();
      if (this.mode == 2) this.setRegulateur();
    },
    setExistant() {
      this.isProjet = false;
      if (this.mode == 1) this.setEquipement();
      if (this.mode == 2) this.setRegulateur();
    },
    setEquipement() {
      if (this.isProjet) this.setPrEquipement(); else this.setExEquipement();
      this.mode = 1;
    },
    setExEquipement() {
      this.typefeu = true;
      markregul.setZIndex(0);
      markaction.setZIndex(1);
      markprequip.setZIndex(2);
      markprregul.setZIndex(3);
      markequip.setZIndex(6);
    },
    setPrEquipement() {
      this.typefeu = true;
      markregul.setZIndex(0);
      markaction.setZIndex(1);
      markequip.setZIndex(2);
      markprregul.setZIndex(3);
      markprequip.setZIndex(6);
    },
    setRegulateur() {
      if (this.isProjet) this.setPrRegulateur(); else this.setExRegulateur();
      this.mode = 2;
    },
    setExRegulateur() {
      this.typefeu = false;
      markaction.setZIndex(0);
      markequip.setZIndex(1);
      markprregul.setZIndex(2);
      markprequip.setZIndex(3);
      markregul.setZIndex(6);
    },
    setPrRegulateur() {
      this.typefeu = true;
      markregul.setZIndex(0);
      markaction.setZIndex(1);
      markequip.setZIndex(2);
      markprequip.setZIndex(3);
      markprregul.setZIndex(6);
    },
    setAction() {
      this.typefeu = false;
      markequip.setZIndex(0);
      markregul.setZIndex(1);
      markprregul.setZIndex(2);
      markprequip.setZIndex(3);
      markaction.setZIndex(6);
      this.mode = 0;
    },
    setPlan() {
      this.typeplan = true;
      this.osm.setZIndex(5);
      this.xyz.setZIndex(4);
    },
    setSatellite() {
      this.typeplan = false;
      this.osm.setZIndex(4);
      this.xyz.setZIndex(5);
    },
    alt(statut) {
      return "Equipement " + (statut != 0 ? "en panne" : "fonctionnel") + ",Voir les détails";
    },
    doc(code) {
      let ret = getPrefix() + "/doc/feux/" + code;
      return ret;
    },
    action(mesure) {
      if (mesure == null) return "";
      let cd = mesure.candela;
      if (cd < mesure.cdminimal) {
        return "Feu HS";
      } else if (cd > mesure.cdmaintenance) {
        return "Feu Ok";
      } else {
        return "Prévoir action";
      }
    },
    couleur(mesure) {
      if (mesure == null) return "green";
      let cd = mesure.candela;
      if (cd < mesure.cdminimal) {
        return "red";
      } else if (cd > mesure.cdmaintenance) {
        return "green";
      } else {
        return "orange";
      }
    },
    action2(mesure) {
      if (mesure == null) return "";
      let cd = mesure.candela2;
      if (cd < mesure.cdminimal2) {
        return "Feu HS";
      } else if (cd > mesure.cdmaintenance2) {
        return "Feu Ok";
      } else {
        return "Prévoir action";
      }
    },
    couleur2(mesure) {
      if (mesure == null) return "green";
      let cd = mesure.candela2;
      if (cd < mesure.cdminimal2) {
        return "red";
      } else if (cd > mesure.cdmaintenance2) {
        return "green";
      } else {
        return "orange";
      }
    },
    viewfeu(id) {
      let view = this.map.getView();
      store.commit('zoom', { zoom: view.getZoom(), center: view.getCenter() })
      this.$router.push({ path: "equipement/" + id });
    },
    viewdetailfeu(id) {
      this.detailchambre = false
      this.idModal = id;
      this.feuModal = this.feux.find(function (feu, index) {
        if (feu.idfeu == id) return true;
      });
      this.getFeu(id, this.feuModal.idimage);
    },
    firstdetail() {
      if (this.idfeu == 0) return;
      this.viewdetailfeu(this.idfeu);
    },
    viewdetailchambre(id) {
      this.detailfeu = false
      this.getChambre(id);
    },
    hidedetail() {
      this.detailfeu = false;
      this.detailchambre = false;
    },
    initMap(isChambre) {
      this.osm = new TileLayer({
        source: new OSM()
      });

      this.xyz = new TileLayer({
        source: new XYZ({
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        })
      });

      vectorEquip = new VectorSource({
        features: equip,
      });

      markequip = new VectorLayer({
        source: vectorEquip,
      });

      vectorPrEquip = new VectorSource({
        features: prequip,
      });

      markprequip = new VectorLayer({
        source: vectorPrEquip,
      });

      vectorRegul = new VectorSource({
        features: regul,
      });

      markregul = new VectorLayer({
        source: vectorRegul,
      });

      vectorPrRegul = new VectorSource({
        features: prregul,
      });

      markprregul = new VectorLayer({
        source: vectorPrRegul,
      });

      vectorAction = new VectorSource({
        features: action,
      });

      markaction = new VectorLayer({
        source: vectorAction,
      });

      vectorChambre = new VectorSource({
        features: chb,
      });

      markchambre = new VectorLayer({
        source: vectorChambre,
      });

      let view = null;
      let center = this.mapCenter;

      if (this.idfeu != 0) {
        let zoom = 23;

        this.feux.forEach((feu) => {
          if (feu.idfeu == this.idfeu) {
            console.log(feu.idfeu, feu.plat, feu.plng)

            view = new View({
              center: transform([feu.flng, feu.flat], 'EPSG:4326', 'EPSG:3857'),
              zoom: zoom,
              rotation: cvRotation(this.rotation)
            })
          }
        })
      } else {
        let zoom = 15;

        if (store.state.zoom != 0) {
          view = new View({
            center: store.state.center,
            zoom: store.state.zoom,
            rotation: cvRotation(this.rotation)
          });
        } else {
          view = new View({
            center: transform([center.lng, center.lat], 'EPSG:4326', 'EPSG:3857'),
            zoom: zoom,
            rotation: cvRotation(this.rotation)
          })
        }
      }

      store.commit('zoom', { zoom: 0, center: {} })

      let map = new Map({
        layers: [markregul, markprequip, markprregul, markaction, this.xyz, this.osm, markequip, markchambre],
        target: 'map',
        view: view,
      });

      this.setPlan();
      this.setEquipement();

      markchambre.setZIndex(0);

      map.on("click", function (e) {
        map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {

          EventBus.$emit("click-marker", {
            feature: feature,
          });
        })
      });

      this.map = map;
    },
    getChambre(id) {
      this.detailchambre = true;

      let sql =
        "SELECT c.nom,if(c.ismassif=1,'Massif','Chambre') as massif,tc.libelle as typechambre,mt.libelle as mattampon,ft.libelle as formetampon,re.libelle AS resistance" +
        " FROM chambre AS c" +
        " LEFT JOIN cgbcommun.tableref AS tc ON tc.id=c.idtypechambre" +
        " LEFT JOIN cgbcommun.tableref as mt ON mt.id=c.idmattampon" +
        " LEFT JOIN cgbcommun.tableref as ft ON ft.id=c.idformetampon" +
        " LEFT JOIN cgbcommun.tableref AS re ON re.id=c.idresistancetampon" +
        " WHERE c.id=" + id;
      selectApi(this, sql).then(
        (response) => {
          if (response.data.count > 0) {
            this.chambremodal = response.data.result[0];
            this.detailchambre = true;
          }
        },
        (response) => {
          this.detailchambre = false;
        }
      );
    },
    getFeu(id, idimg) {
      this.serigraphie = false;

      if (idimg != 0) {
        imgApi(this, idimg).then(
          (response) => {
            this.nomfichier = response.data.result.replace('./public', getPrefix());
            this.serigraphie = true;
          },
          (response) => {
          }
        );
      }

      let sql =
        "SELECT m.candela,m.candela2,left(m.Dt,10) as dt10,fp.cdnominal,fp.cdminimal,fp.cdnominal - (fp.cdminimal / 2) as cdmaintenance" +
        ",fp.cdnominal as cdnominal2,fp.cdminimal as cdminimal2,fp.cdnominal - (fp.cdminimal / 2) as cdmaintenance2,c.strcode,m.sens" +
        ",f.idfonction,tf.libelle as typefeu,f.serigraphie,IF(tf.strcode2 is null,'FEU.png',tf.strcode2) as imgfeu,f.statut,f.angle" +
        ",m.mesure,m.mesure2 FROM mesurefeu as m" +
        " LEFT JOIN feu as f ON f.id=m.idfeu" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref as eq ON eq.id=f.idfonction" +
        " LEFT JOIN cgbcommun.tableref as tf ON tf.id=f.idtype" +
        " LEFT JOIN fonctionpiste as fp ON fp.idequipement=eq.id" +
        " WHERE (m.mesure=1 OR m.mesure2=1) AND m.idfeu=" +
        id +
        " ORDER BY m.Dt DESC LIMIT 1";
      selectApi(this, sql).then(
        (response) => {
          this.countm = response.data.count;
          this.mesures = response.data.result;

          if (this.countm > 0) {
            this.mesures.forEach((mesure) => {

              if (mesure.mesure == 1) {
                let attenuation = calcAttenuation(mesure.idfonction, 0, mesure.strcode)
                mesure.cdminimal *= attenuation;
                mesure.cdnominal *= attenuation;
                mesure.cdmaintenance *= attenuation;
              }
              if (mesure.mesure2 == 1) {
                let attenuation = calcAttenuation(mesure.idfonction, 1, mesure.strcode)
                mesure.cdminimal2 *= attenuation;
                mesure.cdnominal2 *= attenuation;
                mesure.cdmaintenance2 *= attenuation;
              }
            });
            this.mesure = this.mesures[0];
          } else this.mesure = null;
          this.detailfeu = true;
        },
        (response) => {
          this.countm = 0;
          this.mesures = [];
          this.detailfeu = true;
          this.mesure = null;
        }
      );
    },
    svg(code) {
      let path = getPath(code, 0, false);
      return path;
    },
    refreshFeux() {
      let id = store.state.piste;
      let sql =
        "SELECT f.id as idfeu,f.nom as nomfeu,f.latitude as flat,f.longitude as flng,r.id as idreg,r.nom as nomreg,c.libelle as couleur," +
        "c.strcode,p.latitude as plat,p.longitude as plng,f.statut,f.idfonction,f.idimage," +
        "(SELECT count(*) FROM mesurefeu WHERE idfeu=f.id) as nblux,p.rotation,f.idfonction,tf.libelle as typefeu,f.serigraphie,f.angle," +
        "(SELECT COUNT(*) FROM actionpiste WHERE solde=0 AND idfeu=f.id) AS nbaction," +
        "IF(tf.strcode2 is null,'FEU.png',tf.strcode2) as imgfeu," +
        "IF(tf.strcode3 is null,'CGB.pdf',tf.strcode3) as docfeu," +
        "r.couleur as regcouleur,f.projet" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref as tf ON tf.id=f.idtype" +
        " LEFT JOIN piste as p ON p.id=f.idpiste" +
        " WHERE f.idpiste=" +
        id;
      selectApi(this, sql).then(
        (response) => {
          this.count = response.data.count;
          this.feux = response.data.result;
          if (this.count > 0) {
            this.mapCenter.lat = this.feux[0].plat;
            this.mapCenter.lng = this.feux[0].plng;
            this.rotation = this.feux[0].rotation;
            equip = [];
            prequip = [];
            regul = [];
            prregul = [];
            action = [];
            this.maxaction = 0;
            this.modeProjet = false;
            this.feux.forEach((feu) => {
              if (feu.nbaction > this.maxaction) this.maxaction = feu.nbaction;
              if (feu.projet != 0) this.modeProjet = true;
            })

            let point0 = { lat: window.myPos.lat, lng: window.myPos.lng };

            const cible = target();
            equip.push(setMarker(point0, cible));
            prequip.push(setMarker(point0, cible));
            regul.push(setMarker(point0, cible));
            prregul.push(setMarker(point0, cible));
            action.push(setMarker(point0, cible));
            mark = true;

            this.feux.forEach((feu) => {
              feu.imgfeu = getPrefix() + "/img/" + feu.imgfeu;
              feu.imgStatut = getPrefix() + "/img/" + (feu.statut == 0 ? "OK.png" : "HS.png");
              let angle = feu.angle
              if (angle == -1) angle = 0;

              if (feu.flat != 0 && feu.flng != 0) {
                let point = { lat: feu.flat, lng: feu.flng };

                let path = getPath(feu.strcode, angle, feu.statut != 0, feu.idfonction);
                if (path != "") {
                  if (feu.projet == 0) {
                    if (feu.idfeu == this.idfeu) {
                      equip.push(setMarker(point, path, feu.idfeu, 0, 0, 1));
                    } else {
                      equip.push(setMarker(point, path, feu.idfeu));
                    }
                  } else {
                    prequip.push(setMarker(point, path, feu.idfeu));
                  }
                }

                path = getPath(feu.regcouleur, angle, feu.statut != 0);
                if (path != "") {
                  if (feu.projet == 0) {
                    regul.push(setMarker(point, path, feu.idfeu, feu.idfonction));
                  } else {
                    prregul.push(setMarker(point, path, feu.idfeu, feu.idfonction));
                  }
                }

                if (feu.nbaction > 0) {
                  path = getPath(scaleColor(feu.nbaction, this.maxaction), 0, feu.statut != 0);
                  if (path != "") action.push(setMarker(point, path, feu.idfeu, feu.idfonction));
                }
              }
            });

            this.refreshChambres();
          }
        },
        (response) => {
        }
      );
    },
    refreshChambres() {
      let id = store.state.piste;
      let sql =
        "SELECT c.id,c.nom,c.latitude as lat,c.longitude as lng,c.ismassif" +
        " FROM chambre AS c" +
        " WHERE c.idpiste=" + id + " ORDER BY c.ismassif ASC";
      this.chambreOK = false;
      selectApi(this, sql).then(
        (response) => {
          this.countc = response.data.count;
          this.chambres = response.data.result;
          if (this.countc > 0) {
            this.chambreOK = true;
            chb = [];

            this.chambres.forEach((chambre) => {
              if (chambre.lat != 0 && chambre.lng != 0) {
                let point = { lat: chambre.lat, lng: chambre.lng };
                let path = (chambre.ismassif == 1) ? massif("808080") : chamber("000000");
                if (path != "") {
                  chb.push(setMarker(point, path, chambre.id, 0, 1));
                }
              }
            })

            this.refreshReseaux()

          } else {
            this.initMap(false);
            watchPosition();
            this.firstdetail();
          }
        },
        (response) => {
          this.initMap(false);
          watchPosition();
          this.firstdetail();
        }
      );
    },
    refreshReseaux() {
      let id = store.state.piste
      let sql =
        "SELECT r.id AS idreseau," +
        "co.id AS idchambreorig,co.Latitude as olat,co.Longitude as olng,co.ismassif as omassif," +
        "cd.id AS idchambredest,cd.Latitude as dlat,cd.Longitude as dlng,cd.ismassif as dmassif," +
        "f.id AS idfeudest,f.Latitude as flat,f.Longitude as flng" +
        " FROM reseauchambre AS r" +
        " LEFT JOIN chambre AS co ON co.id=r.idchambre" +
        " LEFT JOIN chambre AS cd ON cd.id=r.idchambredest" +
        " LEFT JOIN feu AS f ON f.id=r.idfeudest" +
        " WHERE co.idpiste=" + id;
      this.linkOK = false;
      selectApi(this, sql).then(
        (response) => {
          this.countr = response.data.count;
          this.reseaux = response.data.result;
          if (this.countr > 0) {
            this.linkOK = true;
            let geo = {
              "type": "FeatureCollection",
              "features": []
            }

            this.reseaux.forEach((reseau) => {
              // chambre <-> Massif
              if (reseau.idchambredest != null) {
                let point1 = { lat: reseau.olat, lng: reseau.olng };
                let point2 = { lat: reseau.dlat, lng: reseau.dlng };
                chb.push(setLine(point1, point2, ((reseau.omassif == 1) || (reseau.dmassif == 1)) ? "red" : "blue"))
              }
              // chambre <-> Feu
              if (reseau.idfeudest != null) {
                let point1 = { lat: reseau.olat, lng: reseau.olng };
                let point2 = { lat: reseau.flat, lng: reseau.flng };
                chb.push(setLine(point1, point2, (reseau.omassif == 1) ? "green" : "magenta"))
              }
            }
            )

          }
          this.initMap(true);
          watchPosition();
          this.firstdetail();
        },
        (response) => {
          this.initMap(true);
          watchPosition();
          this.firstdetail();
        }
      );
    },
  },
  created() {
    window.EventBus = new Vue({
      data() {
        return {
          id: 0
        };
      },
    });

    EventBus.$on("click-marker", (data) => {
      if (data.feature.id_ != undefined) {
        //        console.log("click",data.feature.id_)
        let id = data.feature.id_
        let ischambre = data.feature.feuchambre_
        if (id != 0) {
          if (ischambre == 0) {
            // console.log("Feu")
            this.viewdetailfeu(id)
          } else {
            // console.log("Chambre")
            this.viewdetailchambre(id)
          }
        }
      }
    })
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }
    this.refreshFeux();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

#map {
  width: 100%;
  height: 100vh;
  display: block;
}

.detail {
  padding: 10px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.detail {

  a,
  a:hover,
  a:link {
    text-decoration: none !important;
  }

  a:hover {
    color: darkblue;
    font-weight: bold;
  }
}

.control {
  background-color: $headback-color;
  color: black;
  font-size: 16;

  a,
  a:hover,
  a:link {
    text-decoration: none !important;
  }

  a:hover {
    color: white;
    font-weight: bold;
  }
}
</style>