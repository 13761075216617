export const addslashes = function (s) {
  return s.replace(/\\/g, '\\\\')
    .replace(/\u0008/g, '\\b')
    .replace(/\t/g, '\\t')
    .replace(/\n/g, '\\n')
    .replace(/\f/g, '\\f')
    .replace(/\r/g, '\\r')
    .replace(/'/g, '\\\'')
    .replace(/"/g, '\\"')
}

export const fla = function (s) {
  return s.replace("'", "\\'")
}

export const pad = function (n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const getRandomInt = function (max) {
  return Math.floor(Math.random() * Math.floor(max))
}

export const cvRotation = function (angle) {
  let r = angle;
  r = r * (Math.PI / 180);
  return r;
}

export const calcAttenuation = function (idfonction, nomes, strcode) {

  let attenuation = 1
  if (strcode == null) return 1
  
  let code = ""
  if (nomes == 0) {
    if (strcode.length >= 6) code = strcode.substring(0, 6)
  }
  else {
    if (strcode.length > 6) code = strcode.substring(strcode.length - 6)
  }

  // 130-Axe de piste , 57-Bord de piste
  if (idfonction == 130 || idfonction == 57) {
    // Rouge
    if (code == "FF0000") attenuation = 0.15
    // Jaune
    if (code == "FFDE43") attenuation = 0.4
  }

  // 295-Seuil/Extrémité
  if (idfonction == 295) {
    // Rouge
   if (code == "FF0000") attenuation = 0.25
  }

  return attenuation
}
